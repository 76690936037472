<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

const { getPromotion, getPromoBannerSettings } = usePromotionData();
const { data } = await getPromotion();
const { data: promoBanner } = await getPromoBannerSettings();
const featuredPromos = []
if (data.value.promotion.featuredPromo.length > 0) {
  for (const promo of data.value.promotion.featuredPromo) {
    const cardEntry = {
      id: promo.id,
      attributes: {
        title: promo.attributes.discountName,
        description: promo.attributes.discountBannerOfferText,
        btnText: 'View Details', 
        mobileDescription: promo.attributes.discountBannerOfferTextMobile,
        createdAt: promo.attributes.createdAt,
        updatedAt: promo.attributes.updatedAt,
        promoCode: promo.attributes.discountCode,
        longDescription: promo.attributes.discountDescription, 
        showOnBanner: true,
        image: {
          url: promo.attributes.discountBannerImageURL ? promo.attributes.discountBannerImageURL : 'https://placehold.co/100x100'
        },
        discountCode: promo.attributes.discountCode
      }
    };
    featuredPromos.push(cardEntry)
  }
}
const otherPromos = data.value.promotion.promos.filter(card => card.attributes.showOnBanner)
const cards = featuredPromos.concat(otherPromos)
// Carousel options
const options = {
  perMove: 1,
  pagination: false,
  type: 'slide',
  speed: 500,
  fixedWidth: '470px',
  arrows: true,
  gap: '40px',
  breakpoints: { // These are mobile first
    666: {
      destroy: true
    },
  }
}

const expanded = ref(true)

const observeNavbar = () => {
  const header = document.getElementById("site-header");
  const config = { attributes: true, childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "attributes" && mutation.attributeName === 'class') {
        if (header.classList.contains('sticky')) {
          expanded.value = false;
        }
        else {
          expanded.value = true;
        }
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(header, config);
}

const bannerClass = computed(() => {
  return {
    'expanded': expanded.value
  }
})

const isExpanded = ref(false);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

onMounted(() => {
  // Event listener to toggle expand on click
  document.getElementById('headingOne').addEventListener('click', toggleExpand);
  observeNavbar()
})
</script>

<template>
  <div id="promo-banner" class="px-2 position-fixed w-100 zindex-dropdown fixed-top" :class="[bannerClass, { 'expanded-promo': isExpanded }]">
    <div class="accordion  border-0" >
      <div class="container g-0">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <span class="w-100 d-none d-sm-block">{{ featuredPromos[0] ? featuredPromos[0].attributes.title : promoBanner.attributes.bannerTitle }}</span>
              <span class="w-100 d-block d-sm-none">{{ featuredPromos[0] ? featuredPromos[0].attributes.title : promoBanner.attributes.mobileBannerTitle }}</span>
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse mb-2" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            
              <div class="accordion-body">
                <div class="mb-2 text-center">
                  <p class="d-none d-sm-block">{{ promoBanner.attributes.bannerSubtitle }}</p>
                  <p class="d-block d-sm-none">{{ promoBanner.attributes.mobileBannerSubtitle }}</p>
                </div>
                <Splide :options="options" ref="splide">
                  <SplideSlide v-for="card in cards" :key="`banner-${card.id}`" >
                    <PromotionsCard :card="card" />
                  </SplideSlide>
                </Splide>
              </div>
          </div>
        </div>
      </div>
        
    </div>
  </div>
  
</template>

<style lang="scss">
#promo-banner {
  background-image: linear-gradient(0deg, #3cad9f,  #65c96b);

  @include media-breakpoint-down(sm) {
    &.expanded-promo {
      height: 100%;

      .accordion {
        height: calc(100vh - 7px);
      }
    }
  }

  &:not(.expanded) {
    .accordion-button {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .container {
    --bs-gutter-x: 0;
  }

  .accordion
  {
    background-image: linear-gradient(0deg, #3cad9f,  #65c96b);
  }
  
  .accordion:has(.collapsing),
  .accordion:not(:has(.accordion-button.collapsed)) {
    background-color: $color-secondary;
    background-image: none;
  }
  
  .accordion-item {
    border-radius: 0;
    border: 0;
    background-color: transparent;
  }

  .accordion-header {
    border-radius: 0;
    border: 0;

    .accordion-button {
      border: 0;
      background-color: transparent;
      color: $color-secondary;
      border-radius: 0;      

      &::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>');
        transition: all .5s;
        color: $color-secondary;
      }

      &:not(.collapsed) {
        box-shadow: none;
        border: 0;
        color: $color-body-text;

        &::after {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>');
          transform: rotate(45deg); 
          transition: all .3s;
          color: $color-body-text;
        }
      }
      &:focus {
        box-shadow: none;
        border: none;
        outline: 0;
      }
    }
  }
  
  .accordion-collapse {
    background-color: $color-secondary;
    border-radius: 0;
    max-height: calc(100vh - 90px);
    @include media-breakpoint-down(sm) {       
      overflow-y: auto;
    }
  }

  .splide {
    .splide__arrows {
      .splide__arrow {
        width: 40px ;
        height: 40px ;
        border: solid 1px #d9d9d9;
        svg {
          fill: #121212;
        }

        .bi {
          &::before {
            font-weight: bold !important;
            font-size: 16px;
          }
        }

      }
    }
  }
  
}
</style>